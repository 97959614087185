import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo/Seo";
import { BlockA, BlockC1, BlockC2, BlockQuote, BlockE, BlockHeader } from '../components/BlockModule/BlockModule';
import ImageOne from './assets/The Team_image 1@2x.jpg';
import ImageTwo from './assets/The Team_image 2@2x.jpg'
import { setNavColor, setCurrentPage, setNavDefaultColor } from "../actions";

// markup
const TeamPage = ({data}) => {
  const dispatch = useDispatch();
  const document = data.allPrismicTeam.edges[0].node.data.body;
  const BlockQuoteData = document[0].primary.quote;
  const BlockEData = document[1].items.map((item) => {
    return {
      title: item.title,
      html: item.copy.html
    }
  })
  const BlockHeaderData = document[2].primary.title;
  const BlockC1Data = document[3].primary;
  const BlockC2Data = document[4].primary;

  useEffect(() => {
    dispatch(setCurrentPage('/team'));
  },[]);
  return (
    <Layout defaultColor='black' footer>
      <Seo title="Team"/>
        <BlockQuote animated large tight>{BlockQuoteData}</BlockQuote>
        <BlockE animated data={BlockEData} />
          <BlockHeader animated>{BlockHeaderData}</BlockHeader>
          <BlockC1 animated none large={false} image={{src: BlockC1Data.image.url, placeholder: BlockC1Data.image.url+'&w=100&blur=10&q=50', width:BlockC1Data.image.dimensions.width, height:BlockC1Data.image.dimensions.height}} title={BlockC1Data.title} html={BlockC1Data.copy.html} />
          <BlockC2 animated image={{src: BlockC2Data.image.url, placeholder: BlockC2Data.image.url+'&w=100&blur=10&q=50', width:BlockC2Data.image.dimensions.width, height:BlockC2Data.image.dimensions.height}} title={BlockC2Data.title} html={BlockC2Data.copy.html}/>
    </Layout>

  )
}

export const query = graphql`
query TeamQuery {
  allPrismicTeam {
    edges {
      node {
        data {
          body {
            ... on PrismicTeamBodyBlockQuote {
              id
              primary {
                quote: copy
              }
            }
            ... on PrismicTeamBodyBlockE {
              id
              items {
                title
                copy {
                  html
                }
              }
            }
            ... on PrismicTeamBodyBlockHeader {
              id
              primary {
                title
              }
            }
            ... on PrismicTeamBodyBlockC1 {
              id
              primary {
                copy {
                  html
                }
                title
                image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
            }
            ... on PrismicTeamBodyBlockC2 {
              id
              primary {
                title
                copy {
                  html
                }
                image {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default TeamPage